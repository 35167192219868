.ZodiacContainer{
    height: 100%;
    padding: 50px;
    margin-top: 100px;
    display: flex;
}
.zodiacContent{
    width: 80%;
    margin-left: 30px;
    padding: 25px;
    height: 150vh;
}
.zodiacContent img{
    height: 200px;
    width: 200px;
}
.barline{
    width: 10px;
    background-color: #c9c3c3;
    border-width: 0px;
}
.zodiacContent hr{
    width: 70%;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 3px;
    background-color: #6a6767;
}

.SignsSection{
    display: flex;
    gap: 20px;
}
.signsContainer{
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    
    align-items: center;
}
.signsContainer h2{
    font-weight: 600;
    font-size: 22px;
   
}
.signsContainer p{
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
}
.activeItem{
    text-decoration: underline;
    color: #d83e3e;
}