.talk-to-astrologer-container {
    text-align: center;
    padding: 20px;
    visibility: inherit;
  }
  .chat-to-astrologer-container{
    height: 100vh;
    
  }
  
  .astrologer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .astrologer-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
 
  
  .astrologer-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .call-button {
    background-color: #ff5722;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .call-button:hover {
    background-color: #e64a19;
  }
  
  .talk-to-astrologer-container {
    text-align: center;
    padding: 20px;
  }
  
  .astrologer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  .astrologer-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    
  }
  

  
  .astrologer-photo {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .call-button {
    background-color: #ff5722;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .call-button:hover {
    background-color: #e64a19;
  }
  
  .talk-heading {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  h3 {
    margin: 10px 0;
    font-size: 1.5rem;
  }
  
  p {
    margin: 5px 0;
  }
  
  /* Pagination controls */
  .pagination-controls {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .pagination-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
   .talk-heading{
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  h3 {
    margin: 10px 0;
    font-size: 1.5rem;
  }
  
  p {
    margin: 5px 0;
  }
  
  /* Pagination controls */
  .pagination-controls {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  
  .pagination-button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .pagination-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .astrologer-talk-main-container{
    height: 100%;
    margin: 50px;
    width: 90%;
    }
  .category-select {
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    margin-left: 10px;
  }
  
  /* Search input */
  .search-input {
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 300px;
  }

 
  
  /* Filter controls container */
  .filter-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .astrologer-talk-main-container {
    min-height: 100vh;
    padding-top: 50px;
    padding-right: 20px;
    margin-right: 10px;
  }
  .sort-select {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .popup-container {
    text-align: center;
    position: relative; /* Add this to position the overlay correctly */
}

.trigger-button {
    border-width: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: gold;
    height: 40px;
    width: 100px;
    border-radius: 17px;
    font-size: 20px;
    border: none;
    font-weight: 450;
    cursor: pointer;

}

.trigger-button:hover {
    cursor: pointer;
}

.popup-container-display {
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    width: 300px;
    z-index: 1001; /* Ensure it's above the overlay */
    position: relative; /* Add this */
}

.form-input-label {
    margin-bottom: 15px;
}

input {
    width: 92%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
}

.get-otp-btn {
    background-color: #db940f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
}

.get-otp-btn:hover {
    background-color: #45a049;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-top: 10px;
}

.loader-container {
    display: flex;
    justify-content: center;
}

.close-icon {
    cursor: pointer;
    float: right;
    font-size: 20px;
    color: #ff0000;
}


.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark background with transparency */
    z-index: 999; /* Ensure it's above everything else */
    display: none; /* Hidden by default */
}


.popup-active .overlay {
    display: block;
}

.google-signin-btn {
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    border: solid 1px #766868;
    margin-top: 20px;
    border-radius: 10px;
    padding: 3px;
}

.google-signin-btn:hover {
    cursor: pointer;
}

.or {
    text-align: center;
    margin-top: 20px;
}

.google-icon {
    height: 30px;
    width: 30px;
}

.astro-popup-overlay {
    background-color: rgba(0, 0, 0, 0.6); 
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
