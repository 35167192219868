.container {
    padding: 40px; 
    max-width: 100%;
    margin: 70px auto; 
    background-color: #f9f9f9; 
    border-radius: 12px; 
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1); 
    font-family: Arial, sans-serif;
    color: #333;
  }
  
  .heading1 {
    font-size: 2.2rem; 
    margin-bottom: 24px;
    color: #0056b3; 
    padding-bottom: 12px; 
    font-weight: bold; 
    position: relative; 
    display: inline-block; 
  }
  
  .heading1::after {
    content: ""; 
    display: block;
    width: 60%; 
    height: 2px; 
    background-color: #0056b3; 
    position: absolute;
    bottom: 0; 
    left: 20%; 
  }
  
  .heading2 {
    font-size: 1.5rem; 
    margin-top: 25px; 
    margin-bottom: 20px; 
    color: #34495e; 
  }
  
  .paragraph {
    font-size: 1.1rem; 
    color: #495057; 
    line-height: 1.8; 
    margin-bottom: 20px; 
  }
  
  .list {
    margin: 20px 0; 
    padding: 0 20px;
  }
  
  .listItem {
    margin-bottom: 15px; 
  }
  
  .informationSection p {
    margin-bottom: 20px; 
  }
  