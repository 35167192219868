.BlogContainer{
    padding: 20px;
    height: 120vh;
    margin-top: 100px;
}
.BlogContent{
    color: #000;
    box-shadow: 0px 4px 8px 0px rgba(128, 128, 128, 0.5);
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 10px;
    line-height: 1.6;
}
.BlogContent ul li{
    list-style: initial;
}
.BlogContent p {
    font-size: 18px;
    font-weight: 530;
    
}

@media screen and (max-width:576px) {
    .BlogContent h1{
        font-size: 14px;
    }
    .BlogContent p{
        font-size: 10px;
    }
    .BlogContent li{
        font-size: 10px;
    }
    .BlogContent{
        padding: 20px;
    }
    
}