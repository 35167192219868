* {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
.maincontainer {
    height: 100vh;
    width: 100vw;
    /* background-color: red; */
}
.subcontaineroneofheaderconatiner:hover{
    cursor: pointer;
}
.menubar{
    display: none;
}
.sideBar{
    display: none;
}
.sideBarActive{
    display: none;
}
.sideBarActive{
    display: none;
}

.headercontainer {
    height: 80px;
    width: 100vw;
    background-color: white;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-around;
    
}
.subcontaineroneofheaderconatiner {
    height: 70px;
    width: 250px;
    /* background-color: blue; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}
#horoscope {
    height: 50px;
    width: 50px;
}
.subcontainertwoofheaderconatiner {
    font-size: 10px;
    font-weight: normal;
    height: 70px;
    width: 720px;
    /* background-color: blue; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    
}
.subcontainertwoofheaderconatiner h1:hover{
    color: #e39023;
    text-decoration: underline;
}
#buttonforlogin {
    background-color: gold;
    height: 40px;
    width: 100px;
    border-radius: 17px;
    font-size: 20px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: 450;
    cursor: pointer;

    
}
#buttonforlogin:hover{
    transition: transform 0.5s ease;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5)
}
#setimageinsideloginbutton {
    height: 22px;
    width: 22px;
}

.subcontainertwoofheaderconatiner h1{
    margin: 0px 2px 0px 2px ;
}


input[type="checkbox"] {
    cursor: pointer;
  }
  


.bodycontainerone {
    padding-top: 100px;
    height: 500px;
    width: 100vw;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
.childcontoneofbodycontone {
    height: 300px;
    width: 1000px;
    background: linear-gradient(to right, yellow, white, yellow);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.classforchatnow {
    height: 120px;
    width: 330px;
    /* background-color: red; */
    display: flex;
    align-items: start;
    justify-content: space-around;
    flex-direction: column;
}

#idforastrologerimage {
    height: 200px;
    width: 200px;
    border-radius: 20px;
}

#buttonforchatnow {
    height: 50px;
    width: 200px;
    font-size: 25px;
    background-color: black;
    color: white;
    border-radius: 15px;
    cursor: pointer;
}
#buttonforchatnow:hover{
    transition: transform 0.5s ease;
    box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5)
}
.childconttwoofbodycontone {
    height: 140px;
    width: 1000px;
    /* border: 2px solid white; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.subchildconttwoofbodycontone {
    height: 120px;
    width: 240px;
    background-color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    font-size: 12px;
    font-weight: normal;
    /* z-index: 1;    */
}
.subchildconttwoofbodycontone:hover{
    transition: transform 0.2s ease;
    transform: scale(1.05);
    cursor: pointer;
}

img {
    height: 50px;
    width: 50px;
}



.homeAstroping:hover {
    transition: transform 0.2s ease;
}




.bodycontainerthree {
    height: 400px;
    width: 100vw;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
.childcontofbodycontthree {
    width: 1000px;
    height: 315px;
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.subchildofchildcontofbodycontthree {
    height: 280px;
    width: 225px;
    background-color: rgb(236, 236, 97);
    border: 1px solid rgb(235, 233, 233);
    border-radius: 10px;
    box-shadow: 2px -1px 3px rgba(0, 0, 0, 0.05), 2px -1px 3px rgba(0, 0, 0, 0.05), -2px 1px 3px rgba(0, 0, 0, 0.05); 
    visibility: visible;
}
.subchildofchildcontofbodycontthree:hover{
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.9);
    cursor: pointer;
    transition: box-shadow 0.2s ease;
    
}

.supersubchildofbodycontainerthree {
    height: 255px;
    width: 205px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid rgb(226, 223, 223);
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    font-size: 12px;
    padding: 10px;
    cursor: pointer;
}
#firstidforspan {
    color: grey;
    font-weight: bold;
    font-size: 15px;
}
#idtosetcardimages {
    height: 50px;
    width: 50px;
    border-radius: 12px;
}










.bodycontainerfour {
    height: 85px;
    width: 100vw;
    background: linear-gradient(to bottom, yellow, rgb(80, 79, 79));
    display: flex;
    align-items: center;
    justify-content: center;
}
.childoneofbodycontainerfour {
    height: 78px;
    width: 250px;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    font-size: 12px;
    color: white;
}







.bodycontainerfive {
    height: 250px;
    width: 100vw;
    background-color: rgb(242, 209, 24);
    display: flex;
    align-items: center;
    justify-content: center;
}
.childcontofbodycontainerfive {
    height: 240px;
    width: 1000px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    /* background-color: red; */
}
#idfornameourastrologers {
    font-size: 25px;
}
.subchildcontofbodycontainerfive {
    height: 180px;
    width: 1000px;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.classnameforcard {
    height: 160px;
    width: 230px;
    background-color: white;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}
.classNameforalsoavailablein img:hover{
    transform: scale(1.20);
    cursor: pointer;
    transition: box-shadow 0.2s ease;
}

.rightcontofchildcontoffootercontainer span:hover{
    text-decoration: underline;
    color: #e39023;
    cursor: pointer;
}





.bodycontainersix {
    height: 830px;
    width: 100vw;
    background-color: rgb(238, 236, 230);
    display: flex;
    align-items: center;
    justify-content: center;
}
.childcontofbodycontainersix {
    height: 720px;
    width: 1000px;
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}




.footercontainer {
    height: 405px;
    width: 100vw;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    color: white;
}

.childcontoffootercontainer {
    height: 360px;
    width: 1250px;
    /* border: 1px solid white; */
    display: flex;
    align-items: start;
    justify-content: space-between;
}
.leftcontofchildcontoffootercontainer {
    height: 270px;
    width: 450px;
    /* background-color: red; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.subchildofleftcont {
    height: 180px;
    width: 300px;
    /* background-color: blue; */
    display: flex;
    align-items: start;
    justify-content: space-around;
    flex-direction: column;
    color: white;
    padding-left: 8px;
}
.classNameforalsoavailablein {
    height: 50px;
    width: 180px;
    /* background-color: aqua; */
    display: flex;
    align-items: center;
}
.rightcontofchildcontoffootercontainer {
    height: 350px;
    width: 750px;
    /* background-color: green; */
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.impotant_links {
    height: 320px;
    width: 200px;
    /* border: 1px solid white; */
    color: white;
    display: flex;
    align-items: start;
    justify-content: space-around;
    flex-direction: column;
    font-weight: bold;
}
.classNameforcopyrighttext {
    height: 50px;
    width: 750px;
    /* background-color: green; */
    display: flex;
    align-items: center;
    justify-content: center;
    color: grey;
}

#idforquery{
    color:rgb(16, 237, 16);
    font-size: 25px;
    position: relative;
    top: 5px;
    text-decoration: underline;
}
#panchanpandit{
    position: relative;
    left: 5px;
    bottom: 8px;
}
#companyaddress{
    position: relative;
    bottom: 10px;
    font-size: 12px;
}







@media (max-width: 1200px) {
    .childcontoneofbodycontone{
        width: 100%; 
    }
    .childconttwoofbodycontone {
        height: 130px;
        width: 100%;
       
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    #idfornameourastrologers{
        font-size: 1.2rem;
    }
    .bodycontainerfive{
        /* height: 28%;
        width: 100%; */
        height: 160px;
        width: 100vw;
        background-color: rgb(242, 209, 24);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #panchanpandit{
        font-size: 10px;
    }
    .childcontofbodycontainerfive{
        height: 90%;
        width: 100%;
    }
    .subchildcontofbodycontainerfive {
        width: 100%; 
        height: 60%;
    }
    .footercontainer {
        height: 85; 
        padding: 20px 0; 
        text-align: center; 
    }
    .childcontoffootercontainer {
        width: 90%; 
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .bodycontainerfour{
        height: 11%;
        width: 100%;
    }
    .childoneofbodycontainerfour{
        font-size: 0.6rem;
    }
}
.classnameforcard{
    font-size: 12px;
}
@media (max-width: 992px) {
    .headercontainer {
        flex-direction: column; 
        align-items: center; 
    }
    .subcontaineroneofheaderconatiner, 
    .subcontainertwoofheaderconatiner {
        width: 100%; 
        text-align: center; 
    }
     #firstidforspan{
        font-size: 0.23rem;
     }
     #idtosetcardimages{
        height: 30%;
        width: 25%;;
     }
     .bodycontainerthree{
        width: 100%;
        height: 26%;
        font-size: 0.6rem; 
     }
    .childcontofbodycontthree {
        width: 100%;
       height: 115px;
    /* border: 1px solid black; */
    display: flex;
    align-items: center;
    justify-content: space-around;
        
    }
    .subchildofchildcontofbodycontthree {
      
        height: 89px;
        width: 85px;
        background-color: rgb(236, 236, 97);
        border: 1px solid rgb(235, 233, 233);
        border-radius: 10px;
        box-shadow: 2px -1px 3px rgba(0, 0, 0, 0.05), 2px -1px 3px rgba(0, 0, 0, 0.05), -2px 1px 3px rgba(0, 0, 0, 0.05);
        display: flex;
        align-items: center;
        justify-content: center; 
    }
    .supersubchildofbodycontainerthree {
   
        height: 68px;
        width: 85px;
        background-color: white;
        border-radius: 10px;
        border: 1px solid rgb(226, 223, 223);
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        font-size: 3.8px;
        cursor: pointer;
    }
}

@media (max-width: 768px) {
    .headercontainer {
        height: auto; 
        flex-direction: row; 
        align-items: center;
    }
    .subcontaineroneofheaderconatiner{
        width: 35%;
        font-size: 0.43rem;
    }
    .subcontainertwoofheaderconatiner {
        width: 60%; 
        font-size: 0.3rem;
    }
    .sideBarInActive{
        display: none;
    }
    .sideBarActive{
        display: none;
    }
 
    .supersubchildofbodycontainerthree {
        width: 100%; 
    }
    .classforchatnow {
        width: 55%;
        font-size: 0.69rem; 
    }
  
    .bodycontainersix{
        height: 100%;
        padding-left: 0.3rem;
    }
    .childcontofbodycontainersix{
        height: 100%;
        font-size: 0.63rem;
    }

}

@media (max-width: 576px) {
    #buttonforlogin {
        width: 70px; 
        height: 25px;
        font-size: 12px; 
        display: flex;
        align-items: center;
        padding: 2px;
        margin-left:5px ;
        justify-content: space-around;
    }
    #setimageinsideloginbutton{
        height: 10px;
        width: 10px;
    }
    .bodycontainerone{
        height:180px;
        
    }
    #buttonforchatnow{
        height: 40%;
        width: 75%;
        font-size: 1.5rem;
    }
    img{
        height: 20px;
        width: 20px;
    }
    .subchildconttwoofbodycontone{
        height: 48px;
        width: 80px;
        background-color: white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-direction: column;
        font-size: 3.3px;
        font-weight: normal;
    }
    .classnameforcard {
        height: 75px;
        width: 90px;
        background-color: white;
        border-radius: 15px;
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 8px;
    }
    .childconttwoofbodycontone{
        height: 45%;
        display: flex;
        justify-content: space-around;
    }
    #idforastrologerimage{
        height: 90px;
        width: 100px;
    }
    .footercontainer { 
    height: 406px;
    width: 100vw;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    color: white;
    }
    .childcontoffootercontainer{
        height: 98%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .leftcontofchildcontoffootercontainer{
        width: 100%; 
        height: 55%;
        display: flex;
        flex-direction: column; 
        align-items: center; 
    }
    #companyaddress{
        font-size: 9px;
    }
     
    .subchildofleftcont {
        width: 100%; 
        font-size: 0.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: column;
    }
    .rightcontofchildcontoffootercontainer {
        width: 95%; 
        height: 55%;
        display: flex;
        flex-direction: row; 
        align-items: end; 
        justify-content: center;
    }
    #idforquery{
        font-size: 0.6rem;
       position: relative;
       bottom: 10px;
    }
    .impotant_links{
        height: 95%;
        width: 95%; 
        font-size: 0.45rem;
        display: flex;
        align-items: start;
        justify-content: space-around;
        position: relative;
        bottom: 3px;
    }
    .classNameforalsoavailablein{
        height: 30%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    
    .classNameforcopyrighttext {
        width: 100%; 
        margin-top: 10px; 
        text-align: center; 
    }
    .subcontainertwoofheaderconatiner h1{
        width: 35px;
    }
    .subcontainertwoofheaderconatiner{
        display: none;
    }
    .headercontainer{
        display: flex;
        justify-content: space-between;
       
    }
    .menubar{
        margin: 10px;
        display: block;
    }
    .menubar:hover{
        cursor: pointer;
    }
    .subcontaineroneofheaderconatiner{
        width: 120px;
        gap: 0.2px;
    }
    .websiteLogo{
        height: 55px !important;
        width: 50px !important;
    }
    .subchildofleftcont h1{
        font-size: 15px;
    }
    .subchildofleftcont span{
        font-size: 8px !important;
    }

    .classNameforcopyrighttext{
        font-size: 11px;
    }
    .headercontainer{
        height: 18px;
        padding-top: 12px;
        padding-bottom: 12px;
        box-shadow: 0px 4px 10px 0px rgba(109, 108, 108, 0.3);
    }
    #horoscope{
        height: 40px;
        width: 40px;
    }
    #idforastrologerimage{
        width: 60px !important;
        height: 60px !important;
    }
    .childcontoneofbodycontone{
        width: 320px;
        
    }
    .childcontoneofbodycontone h1{
        font-size: 15px;
    }
    #buttonforchatnow{
        height: 20px !important;
        width: 50px !important;
        font-size: 8px !important;
    }
    .classforchatnow{
        height: 50px;
    }
    .childconttwoofbodycontone{
        height: 190px;
    }
    .bodycontainerthree{
        padding-top: 20px;
        height: 180px;
    }
    .bodycontainerthree h1{
        height: 60px;
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .bodycontainerfour{
        height: 70px;
    }
    .childcontofbodycontthree{
        height: 200vh;
        display: flex;
        flex-wrap: wrap;
    }
    #firstidforspan{
        height: 100px;
    }
    #idtosetcardimages{
        height: 50px !important;
    }
    .classnameforcard img{
        height: 14px;
        width: 14px;
    }
    .classnameforcard h1{
        font-size: 12px;
    }
    .classnameforcard span{
        font-size: 8px !important;
    }
    #idfornameourastrologers {
        font-size: 14px !important;
        text-align: center !important;
        padding-right: 2px;
    }
    .classnameforcard{
        height:70px;
        width: 70px;
        padding: 2px;
    }
    .bodycontainersix{
        height: 500px;
        padding-top: 20px;
        padding-bottom: 10px;
    }
    .childoneofbodycontainerfour h1{
        font-size: 15px !important;
        padding: 3px;
    }
    .bodycontainerfour{
        height:80px ;
    }
    .sideBarInActive{
        display: block;
        background-color: white;
        margin-top: 50px;
        padding-left: 10px;
        padding-right: 10px;
        position: fixed;
        top: -8px;
        width: 100%;
        left: -500px;
        transition: left 1s ease;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .sideBarActive h1{
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .sideBarInActive h1{
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .sideBarActive{
        position: fixed;
        background-color: #fff;
        margin-top: 50px;
        padding-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: -3px;
        margin-top: 45px;
        left: 0px;
        width: 100%;
        transition: left 1s ease;
    }
    .horzontalBar{
        width: 100%;
        background-color: rgba(179, 172, 172, 0.5);
        border: none;
        height: 1px;
       
    }
}

@media (min-width: 576px) {
    .sideBarActive,
    .sideBarInActive {
      display: none; /* Hide sidebar on screens wider than 576px */
    }
  }
  



.headingAstrologer{
    font-size: 15px;
}