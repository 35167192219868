.toggleContainer{
    background-color:#e48b26;
    padding: 20px;
    display: flex;
    justify-content: space-around;
    margin-top: 100px;
}
@keyframes pulseEffect {
    0% {
        box-shadow: 0 0 10px rgba(7, 99, 55, 0.5), 0 0 10px rgba(7, 99, 55, 0.3);
    }
    50% {
        box-shadow: 0 0 25px rgba(7, 99, 55, 0.7), 0 0 20px rgba(7, 99, 55, 0.5);
    }
    100% {
        box-shadow: 0 0 15px rgba(7, 99, 55, 0.5), 0 0 10px rgba(7, 99, 55, 0.3);
    }
}

.activeToggle {
    color: #ffffff !important;
    background-color: rgb(4, 4, 4)!important;
    border: 10px solid #007c3e;
    border-radius: 5px;
    cursor: pointer;
    animation: pulseEffect 2s infinite;
    transition: transform 0.3s ease;
}

.activeToggle:hover {
    transform: scale(1.1);
}

.toggleContainer button{
    border-width: 0px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    width: 250px;
    color: #040303;
    background-color: rgb(255, 249, 249);
}

.activeToday {
    background-color: blueviolet;
    color: #fff;
 }

.horoscopeList{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 20px;
    justify-content: center;
    padding-bottom: 20px;
}
.toggleSection{
    font-weight: 650;
    font-size: 18px;
}
 .horoscopeContainer{
    height: 100%;
    
 }

 @media screen and (max-width:576px) {
    
    .toggleSection{
        width: 25px;
        height: 52px;
        margin: 2px;
        font-size: 13px;
    }
 }
