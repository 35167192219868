.container {
    padding: 50px;
    max-width:100%;
    margin: 20px auto;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    font-family: 'Roboto', sans-serif;
    color: #333;
  }

  .heading1 {
    font-size: 2.2rem; 
    margin-bottom: 24px;
    color: #0056b3; 
    padding-bottom: 12px; 
    font-weight: bold; 
    position: relative; 
    display: inline-block; 
  }
  
  .heading1::after {
    content: ""; 
    display: block;
    width: 80%; 
    height: 2px; 
    background-color: #0056b3; 
    position: absolute;
    bottom: 0; 
    left: 0; 
    right: 0;
    margin-left: auto; 
    margin-right: auto; 
  }
  
  
  .heading2 {
    font-size: 1.4rem;
    margin-top: 20px;
    margin-bottom: 15px;
    color: #343a40;
  }
  
  .paragraph {
    font-size: 1rem;
    color: #6c757d;
    line-height: 1.6;
  }
  
  ul {
    margin: 15px 0;
    padding: 0 20px;
    list-style-type: disc;
  }
  
  li {
    margin-bottom: 12px;
  }
  
  p {
    margin-bottom: 15px;
  }
  