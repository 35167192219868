.disclaimerContainer {
    padding: 30px;
    max-width: 80%;
    margin: 90px auto;
    background-color: #fafafa;
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
    font-family: 'Verdana', sans-serif;
    color: #444;
  }
  
  .disclaimerHeading {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #007bff;
    padding-bottom: 10px;
    font-weight: bold;
    position: relative;
    display: inline-block;
  }
  
  .disclaimerHeading::after {
    content: "";
    display: block;
    width: 50%;
    height: 2px;
    background-color: #007bff;
    position: absolute;
    bottom: 0;
    left: 25%;
  }
  
  .disclaimerText {
    font-size: 1rem;
    color: #555;
    line-height: 1.7;
    margin-bottom: 20px;
  }
  
  .disclaimerText:last-of-type {
    margin-bottom: 0;
  }
  