.horoscopeCard{
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
    margin: 5px;
    padding: 15px;
    height: 200px;
    width: 650px;
    border-radius: 10px;
}
.horscopeNameAndImage{
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 10px;
}
@media screen and (max-width:576px) {
    .horscopeNameAndImage h1{
        font-size: 18px;
    }
    .horscopeNameAndImage img{
        height: 50px;
        width: 50px;
    }
    .horoscopeCard p{
        font-size: 12px;
    }
    .horoscopeCard{
        height: 180px;
    }
}


