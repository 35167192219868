.popup-container {
    text-align: center;
  }
  .trigger-button{
    background-color: transparent;
    border-width: 0px;
    display: flex;
    justify-content: center;
    align-items: center;

  }

  

  .trigger-button:hover{
    cursor: pointer;
  }
  
  
  .popup-container-display {
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    width: 300px;
  }
  
  .form-input-label {
    margin-bottom: 15px;
  }
  
  .lab {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
  
  input {
    width: 92%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
  }
  
  .get-otp-btn {
    background-color: #db940f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
  }
  
  .get-otp-btn:hover {
    background-color: #45a049;
  }
  
  .error-message {
    color: red;
    font-size: 12px;
    margin-top: 10px;
  }
  
  .loader-container {
    display: flex;
    justify-content: center;
  }
  
  .close-icon {
    cursor: pointer;
    float: right;
    font-size: 20px;
    color: #434141;
   
  }
 
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dark background with transparency */
    z-index: 999; /* Ensure it's above everything else */
    display: none; /* Hidden by default */
  }
  
  
  .popup-active .overlay {
    display: block;
  }
  .google-signin-btn{
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px;
    border: solid 1px #766868;
    margin-top: 20px;
    border-radius: 10px;
    padding: 3px;
  }
  .google-signin-btn:hover{
    cursor: pointer;
  }
.or{
  text-align: center;
  margin-top: 20px;
}
.google-icon{
  height: 30px;
  width: 30px;
}
.astro-popup-overlay {
  background-color: rgba(0, 0, 0, 0.6); /* Light dark background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.trigger-button-after-login {
  
  border-width: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #db940f;
  padding: 10px;
  cursor: pointer;
  position: relative; 
}

.profile-menu {
  position: fixed;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  border-radius: 5px;
  opacity: 0; /* Start as invisible */
  visibility: hidden; /* Hide element */
  top: 60px;
  right: 80px;
  height: auto;
  z-index: 50;
  transition: opacity 0.3s ease, visibility 0s 0.3s; /* Delayed visibility */
}

.trigger-button-after-login:hover .profile-menu,
.profile-menu:hover {
  opacity: 1; /* Show */
  visibility: visible; /* Make it visible */
  transition-delay: 0s; /* Show immediately */
}

.profile-menu {
  transition-delay: 1s; /* 3 seconds delay before disappearing */
}

.profile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.profile-menu li {
  padding: 8px 12px;
  border-bottom: 1px solid #ccc;
}

.profile-menu li:last-child {
  border-bottom: none;
}

.profile-menu li a {
  text-decoration: none;
  color: #333;
  font-size: 14px;
}

.profile-menu li a:hover {
  color: #db940f;
}
